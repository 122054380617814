import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const AppDownload = () => {
    const [deviceType, setDeviceType] = useState(null);

    useEffect(() => {
        if (navigator.userAgentData) {
            const { platform } = navigator.userAgentData;
            if (/android/i.test(platform)) {
                setDeviceType("android");
            } else if (/ios|iphone|ipad|ipod/i.test(platform)) {
                setDeviceType("ios");
            } else {
                setDeviceType("other");
            }
        } else {
            // Fallback to userAgent string check
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;
            if (/android|silk|kindle|kf/i.test(userAgent)) {
                setDeviceType("android");
            } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                setDeviceType("ios");
            } else {
                setDeviceType("other");
            }
        }
    }, []);

    useEffect(() => {
        if (deviceType === "android") {
            window.location.assign(
                "https://play.google.com/store/apps/details?id=com.emaanreviver.emaanreviver",
            );
        } else if (deviceType === "ios") {
            window.location.assign("https://apps.apple.com/us/app/emaan-reviver/id6444712317");
        }
    }, [deviceType]);

    return (
        <div style={{ textAlign: "center", marginTop: "50px" }}>
            <h4 className="common-class">
                {deviceType === "other" ? (
                    <>
                        Please visit the <Link to="/">website</Link> on a mobile device to download
                        the app.
                    </>
                ) : (
                    "Redirecting to the app store..."
                )}
            </h4>
        </div>
    );
};

export default AppDownload;
