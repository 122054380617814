import React from "react";

const Spinner = (props) => {
    return (
        <div id="loader" {...props}>
            <div className="application-loading-container">
                <div className="application-loading-box">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Spinner;
