import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Spinner from "../Spinner";
import "./page-loader.scss";

const PageLoader = ({ text }) => {
    return (
        <Fragment>
            <Spinner className="main-loader" />
            <div className="info-text relative-center">{text}</div>
        </Fragment>
    );
};

PageLoader.propTypes = {
    text: PropTypes.string,
};

export default PageLoader;
