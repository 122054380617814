/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-expressions */

import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const TermsConditions = () => {
    const menuElements = [
        {
            name: "Home",
        },
        {
            name: "Product",
        },
        {
            name: "About Us",
        },
        {
            name: "Contact Us",
        },
    ];
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
    }, []);

    return (
        <div className="bg-white">
            <div className="header">
                <nav className="navbar navbar-light navbar-expand-lg">
                    <Link className="navbar-brand navbar-brand d-flex mr-auto" to="/">
                        <img src="assets/img/logo.png" alt="Logo" />
                        <h4 className="brand-name">Emaan Reviver</h4>
                    </Link>

                    <div className="navbar-collapse collapse w-100" id="navbarSupportedContent">
                        <ul id="nav" className="navbar-nav ml-auto">
                            {menuElements.map((menu) => {
                                return (
                                    <li key={menu.name} className={`nav-item`}>
                                        <Link className="page-scroll" to="/">
                                            {menu.name}
                                        </Link>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </nav>
            </div>
            <div className="d-flex flex-column privacy-policy">
                <p className="heading">Terms & Conditions</p>
                <div className="content">
                    {/* <p>Last updated: 2024-01-01</p> */}

                    <p>1. Introduction</p>

                    <div className="points-content">
                        <p>Welcome to Emaan Reviver ("Company", "we", "our", "us")!</p>
                        <p>
                            These Terms of Service (“Terms”, “Terms of Service”) govern your use of
                            our website located at www.emaanreviver.com (together or individually
                            “Service”) operated by Emaan Reviver. Our Privacy Policy also governs
                            your use of our Service and explains how we collect, safeguard and
                            disclose information that results from your use of our web pages.
                        </p>
                        <p>
                            Your agreement with us includes these Terms and our Privacy Policy
                            (“Agreements”). You acknowledge that you have read and understood
                            Agreements, and agree to be bound by them.If you do not agree with (or
                            cannot comply with) Agreements, then you may not use the Service, but
                            please let us know by emailing at emaanreviver@gmail.com so we can try
                            to find a solution. These Terms apply to all visitors, users and others
                            who wish to access or use Service.
                        </p>
                    </div>

                    <p>2. Communications</p>

                    <p>
                        By using our Service, you agree to subscribe to newsletters, marketing or
                        promotional materials and other information we may send. However, you may
                        opt out of receiving any, or all, of these communications from us by
                        emailing at emaanreviver@gmail.com.
                    </p>

                    <p>3. Contests, Sweepstakes and Promotions</p>

                    <p>
                        Any contests, sweepstakes or other promotions (collectively, “Promotions”)
                        made available through Service may be governed by rules that are separate
                        from these Terms of Service. If you participate in any Promotions, please
                        review the applicable rules as well as our Privacy Policy. If the rules for
                        a Promotion conflict with these Terms of Service, Promotion rules will
                        apply.
                    </p>

                    <p>4. Content and Services</p>

                    <p>
                        The content and services found on or through Emaan Reviver are the property
                        of the respective content creators who have posted it on Emaan Reviver.
                        Under no circumstances does Emaan Reviver take charge of the activities of
                        content creators. If any inappropriate activities or incidents occur
                        involving any content creators, we urge you to report such activities to
                        Emaan Reviver immediately, and we will take appropriate actions. However,
                        please understand that we are not responsible for any actions or activities
                        performed by individual content creators. Your understanding and vigilance
                        are strictly necessary in this regard. We have established content
                        guidelines that prohibit hate speech, violence, harassment, and illegal
                        activities. If you encounter any content or behaviour that violates these
                        guidelines, we encourage you to report it through our reporting mechanism.
                        We reserve the right to remove or disable access to any content that
                        violates these terms or is harmful to users or Emaan Reviver.
                    </p>

                    <p>5. Prohibited Uses</p>

                    <div className="points-content">
                        <p>
                            You may use Service only for lawful purposes and in accordance with
                            Terms. You agree not to use Service:
                        </p>
                        <p>
                            0.1. In any way that violates any applicable national or international
                            law or regulation.{" "}
                        </p>
                        <p>
                            0.2. For the purpose of exploiting, harming, or attempting to exploit or
                            harm minors in any way by exposing them to inappropriate content or
                            otherwise.{" "}
                        </p>
                        <p>
                            0.3. To transmit, or procure the sending of, any advertising or
                            promotional material, including any “junk mail”, “chain letter,” “spam,”
                            or any other similar solicitation.{" "}
                        </p>
                        <p>
                            0.4. To impersonate or attempt to impersonate Company, a Company
                            employee, another user, or any other person or entity.{" "}
                        </p>
                        <p>
                            0.5. In any way that infringes upon the rights of others, or in any way
                            is illegal, threatening, fraudulent, or harmful, or in connection with
                            any unlawful, illegal, fraudulent, or harmful purpose or activity.{" "}
                        </p>
                        <p>
                            0.6. To engage in any other conduct that restricts or inhibits anyone’s
                            use or enjoyment of Service, or which, as determined by us, may harm or
                            offend Company or users of Service or expose them to liability.
                        </p>
                        <p>Additionally, you agree not to:</p>
                        <p>
                            0.1. Use Service in any manner that could disable, overburden, damage,
                            or impair Service or interfere with any other party’s use of Service,
                            including their ability to engage in real time activities through
                            Service.{" "}
                        </p>
                        <p>
                            0.2. Use any robot, spider, or other automatic device, process, or means
                            to access Service for any purpose, including monitoring or copying any
                            of the material on Service.{" "}
                        </p>
                        <p>
                            0.3. Use any manual process to monitor or copy any of the material on
                            Service or for any other unauthorised purpose without our prior written
                            consent.{" "}
                        </p>
                        <p>
                            0.4. Use any device, software, or routine that interferes with the
                            proper working of Service.{" "}
                        </p>
                        <p>
                            0.5. Introduce any viruses, trojan horses, worms, logic bombs, or other
                            material which is malicious or technologically harmful.{" "}
                        </p>
                        <p>
                            0.6. Attempt to gain unauthorised access to, interfere with, damage, or
                            disrupt any parts of Service, the server on which Service is stored, or
                            any server, computer, or database connected to Service.{" "}
                        </p>
                        <p>
                            0.7. Attack Service via a denial-of-service attack or a distributed
                            denial-of-service attack.{" "}
                        </p>
                        <p>0.8. Take any action that may damage or falsify Company rating. </p>
                        <p>
                            0.9. Otherwise attempt to interfere with the proper working of Service.
                        </p>
                    </div>

                    <p>6. Analytics</p>

                    <p>
                        We don't use any third-party Service Providers to monitor and analyse the
                        use of our Service.
                    </p>

                    <p>7. Parental Guidance for Children’s Use of Our Services</p>

                    <p>
                        While our application is designed to provide Islamic content that is
                        suitable for all ages, we believe it is important for parents to play an
                        active role in monitoring their children’s online activities. Therefore, we
                        encourage parents to supervise their children while using our services.
                        Although our content does not have any restrictions for children, parental
                        guidance can help ensure a safe and beneficial experience for them. We
                        appreciate your understanding and cooperation in this matter. As a reminder,
                        while we strive to provide a safe environment, the responsibility for any
                        actions taken by children while using our services ultimately lies with
                        their parents or guardians.
                    </p>

                    <p>8. Accounts</p>

                    <div className="points-content">
                        <p>
                            When you create an account with us, you guarantee that you are above the
                            age of 18, or if you are under 18, you confirm that you have parental
                            guidance. The information you provide us must be accurate, complete, and
                            current at all times. Inaccurate, incomplete, or obsolete information
                            may result in the immediate termination of your account on Service.
                        </p>
                        <p>
                            You are responsible for maintaining the confidentiality of your account
                            and password, including but not limited to the restriction of access to
                            your computer and/or account. You agree to accept responsibility for any
                            and all activities or actions that occur under your account and/or
                            password, whether your password is with our Service or a third-party
                            service. You must notify us immediately upon becoming aware of any
                            breach of security or unauthorised use of your account.
                        </p>
                        <p>
                            You may not use as a username the name of another person or entity or
                            that is not lawfully available for use, a name or trademark that is
                            subject to any rights of another person or entity other than you,
                            without appropriate authorization. You may not use as a username any
                            name that is offensive, vulgar or obscene. We reserve the right to
                            refuse service, terminate accounts, remove or edit content, or cancel
                            orders in our sole discretion.
                        </p>
                    </div>

                    <p>9. Copyright Policy</p>

                    <div className="points-content">
                        <p>
                            We respect the intellectual property rights of others. It is our policy
                            to respond to any claim that Content posted on Service infringes on the
                            copyright or other intellectual property rights (“Infringement”) of any
                            person or entity.
                        </p>
                        <p>
                            If you are a copyright owner, or authorised on behalf of one, and you
                            believe that the copyrighted work has been copied in a way that
                            constitutes copyright infringement, please submit your claim via email
                            to emaanreviver@gmail.com, with the subject line: “Copyright
                            Infringement” and include in your claim a detailed description of the
                            alleged Infringement as detailed below, under “DMCA Notice and Procedure
                            for Copyright Infringement Claims” You may be held accountable for
                            damages (including costs and attorneys’ fees) for misrepresentation or
                            bad-faith claims on the infringement of any Content found on and/or
                            through Service on your copyright.
                        </p>
                    </div>

                    <p>10. DMCA Notice and Procedure for Copyright Infringement Claims</p>

                    <div className="points-content">
                        <p>
                            You may submit a notification pursuant to the Digital Millennium
                            Copyright Act (DMCA) by providing our Copyright Agent with the following
                            information in writing (see 17 U.S.C 512(c)(3) for further detail):
                        </p>
                        <p>
                            0.1. an electronic or physical signature of the person authorized to act
                            on behalf of the owner of the copyright's interest;
                        </p>
                        <p>
                            0.2. a description of the copyrighted work that you claim has been
                            infringed, including the URL (i.e., web page address) of the location
                            where the copyrighted work exists or a copy of the copyrighted work;
                        </p>
                        <p>
                            0.3. identification of the URL or other specific location on Service
                            where the material that you claim is infringing is located;
                        </p>
                        <p>0.4. your address, telephone number, and email address;</p>
                        <p>
                            0.5. a statement by you that you have a good faith belief that the
                            disputed use is not authorized by the copyright owner, its agent, or the
                            law;
                        </p>
                        <p>
                            0.6. a statement by you, made under penalty of perjury, that the above
                            information in your notice is accurate and that you are the copyright
                            owner or authorized to act on the copyright owner's behalf.
                        </p>
                        <p>
                            You can contact our Copyright Agent via email at emaanreviver@gmail.com.
                        </p>
                    </div>

                    <p>11. Error Reporting and Feedback</p>

                    <p>
                        You may provide us either directly at emaanreviver@gmail.com or via third
                        party sites and tools with information and feedback concerning errors,
                        suggestions for improvements, ideas, problems, complaints, and other matters
                        related to our Service (“Feedback”). You acknowledge and agree that: (i) you
                        shall not retain, acquire or assert any intellectual property right or other
                        right, title or interest in or to the Feedback; (ii) Company may have
                        development ideas similar to the Feedback; (iii) Feedback does not contain
                        confidential information or proprietary information from you or any third
                        party; and (iv) Company is not under any obligation of confidentiality with
                        respect to the Feedback. In the event the transfer of the ownership to the
                        Feedback is not possible due to applicable mandatory laws, you grant Company
                        and its affiliates an exclusive, transferable, irrevocable, free-of-charge,
                        sub-licensable, unlimited and perpetual right to use (including copy,
                        modify, create derivative works, publish, distribute and commercialise)
                        Feedback in any manner and for any purpose.
                    </p>

                    <p>12. Links To Other Web Sites</p>

                    <div className="points-content">
                        <p>
                            While we strive to maintain a safe and respectful environment, our
                            service may contain links to third-party websites or services not owned
                            or controlled by Emaan Reviver. We have no control over and assume no
                            responsibility for their content, products, payments, privacy policies,
                            or practices. We do not endorse the offerings of these entities or
                            individuals. It's important to note that Emaan Reviver is not
                            accountable for content posted by individual creators, including any
                            links, URLs, or donation requests they may share.
                        </p>
                        <p>
                            As a user, you are responsible for verifying the authenticity of any
                            content, links, or URLs before interacting with them. This includes, but
                            is not limited to, clicking on links, navigating to URLs, or making
                            donations.
                        </p>

                        <p>
                            If a content creator requests donations for causes such as mosque
                            construction, food donation, shelter donation, water donation, or any
                            other Sadaqa-e-Jariyah service, or if they are selling a product, please
                            thoroughly verify the authenticity of the request or product before
                            proceeding.
                        </p>

                        <p>
                            Any donations made through links provided by content creators are at
                            your own discretion and risk. Emaan Reviver is not responsible for
                            verifying the authenticity of these donation requests, nor are we
                            involved in the collection of these donations.
                        </p>

                        <p>
                            Emaan Reviver is not responsible for any transactions made between users
                            and content creators. We strongly advise you to verify all details
                            before proceeding with any transaction.
                        </p>

                        <p>
                            If you suspect any fraudulent activity between a user and a content
                            creator, please immediately report your concerns to us at
                            emaanreviver@gmail.com. We take these matters very seriously and will
                            conduct an enquiry into the matter.
                        </p>

                        <p>
                            If, upon enquiry, we find that a content creator is engaging in
                            malpractices, we will take immediate action. This may include suspending
                            the content creator's channel or, if necessary, deleting the channel
                            entirely to maintain the integrity and safety of our community.
                        </p>
                    </div>

                    <p>13. Enhancing User Experience through Updates and New Services</p>

                    <p>
                        While we strive to maintain a focused and beneficial environment for our
                        users, there may be occasions when we share updates or introduce new
                        services that are developed as separate entities. We would be sharing these
                        updates with our Emaan Reviver users with the aim of facilitating our
                        community's needs. We appreciate your understanding and cooperation in this
                        matter, In-Sha-Allah
                    </p>

                    <p>14. Disclaimer Of Warranty</p>

                    <p>
                        THESE SERVICES ARE PROVIDED BY COMPANY ON AN “AS IS” AND “AS AVAILABLE”
                        BASIS. COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS
                        OR IMPLIED, AS TO THE OPERATION OF THEIR SERVICES, OR THE INFORMATION,
                        CONTENT OR MATERIALS INCLUDED THEREIN. YOU EXPRESSLY AGREE THAT YOUR USE OF
                        THESE SERVICES, THEIR CONTENT, AND ANY SERVICES OR ITEMS OBTAINED FROM US IS
                        AT YOUR SOLE RISK. NEITHER COMPANY NOR ANY PERSON ASSOCIATED WITH COMPANY
                        MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS,
                        SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE SERVICES.
                        WITHOUT LIMITING THE FOREGOING, NEITHER COMPANY NOR ANYONE ASSOCIATED WITH
                        COMPANY REPRESENTS OR WARRANTS THAT THE SERVICES, THEIR CONTENT, OR ANY
                        SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL BE ACCURATE, RELIABLE,
                        ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE
                        SERVICES OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER
                        HARMFUL COMPONENTS OR THAT THE SERVICES OR ANY SERVICES OR ITEMS OBTAINED
                        THROUGH THE SERVICES WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS. COMPANY
                        HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
                        STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF
                        MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE. THE
                        FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED
                        UNDER APPLICABLE LAW.
                    </p>

                    <p>15. Limitation Of Liability</p>

                    <p>
                        EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS, DIRECTORS,
                        EMPLOYEES, AND AGENTS HARMLESS FOR ANY INDIRECT, PUNITIVE, SPECIAL,
                        INCIDENTAL, OR CONSEQUENTIAL DAMAGE, HOWEVER IT ARISES (INCLUDING ATTORNEYS’
                        FEES AND ALL RELATED COSTS AND EXPENSES OF LITIGATION AND ARBITRATION, OR AT
                        TRIAL OR ON APPEAL, IF ANY, WHETHER OR NOT LITIGATION OR ARBITRATION IS
                        INSTITUTED), WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS
                        ACTION, OR ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, INCLUDING
                        WITHOUT LIMITATION ANY CLAIM FOR PERSONAL INJURY OR PROPERTY DAMAGE, ARISING
                        FROM THIS AGREEMENT AND ANY VIOLATION BY YOU OF ANY FEDERAL, STATE, OR LOCAL
                        LAWS, STATUTES, RULES, OR REGULATIONS, EVEN IF COMPANY HAS BEEN PREVIOUSLY
                        ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. EXCEPT AS PROHIBITED BY LAW, IF
                        THERE IS LIABILITY FOUND ON THE PART OF COMPANY, IT WILL BE LIMITED TO THE
                        AMOUNT PAID FOR THE PRODUCTS AND/OR SERVICES, AND UNDER NO CIRCUMSTANCES
                        WILL THERE BE CONSEQUENTIAL OR PUNITIVE DAMAGES. SOME STATES DO NOT ALLOW
                        THE EXCLUSION OR LIMITATION OF PUNITIVE, INCIDENTAL OR CONSEQUENTIAL
                        DAMAGES, SO THE PRIOR LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
                    </p>

                    <p>16. Termination</p>

                    <div className="points-content">
                        <p>
                            Please be aware that any attempt to cheat or going against Emaan Reviver
                            guidelines or engage in unauthorised use of EmaanReviver's data or
                            information will result in immediate profile deletion and loss of access
                            to the platform. Our team places great importance on the protection of
                            company data and will take all necessary measures to ensure its
                            confidentiality. We may also terminate or suspend your account and bar
                            access to Service immediately, without prior notice or liability, under
                            our sole discretion, for any reason whatsoever and without limitation,
                            including but not limited to a breach of Terms. If you wish to terminate
                            your account, you may simply discontinue using Service. All provisions
                            of Terms which by their nature should survive termination shall survive
                            termination, including, without limitation, ownership provisions,
                            warranty disclaimers, indemnity and limitations of liability.
                        </p>

                        <p>
                            The Emaan Reviver team reserves the right to accept or reject
                            applications submitted by content creators. This decision is based
                            solely on our platform’s specific vision and guidelines. It is not
                            intended to be a reflection or judgement of the personal beliefs or
                            practices of the content creators.
                        </p>

                        <p>
                            The Emaan Reviver team reserves the right to moderate content on our
                            platform. This includes the right to suspend content creator channels,
                            delete channels, or remove content that contradicts with Islamic
                            Shariah, without prior permission from the content creators. Content
                            creators do not have the right to challenge these moderation decisions.
                        </p>
                    </div>

                    <p>17. Changes To Service</p>

                    <p>
                        We reserve the right to withdraw or amend our Service, and any service or
                        material we provide via Service, in our sole discretion without notice. We
                        will not be liable if for any reason all or any part of Service is
                        unavailable at any time or for any period. From time to time, we may
                        restrict access to some parts of Service, or the entire Service, to users,
                        including registered users.
                    </p>

                    <p>18. Changes to Terms and Conditions</p>

                    <p>
                        We may update our Terms and conditions from time to time. You are advised to
                        review periodically for any changes. Changes to these terms and conditions
                        are effective when they are posted on this page.
                    </p>

                    <p>19. Amendments To Terms</p>

                    <p>
                        We may amend Terms at any time by posting the amended terms on this site. It
                        is your responsibility to review these Terms periodically. Your continued
                        use of the Platform following the posting of revised Terms means that you
                        accept and agree to the changes. You are expected to check this page
                        frequently so you are aware of any changes, as they are binding on you. By
                        continuing to access or use our Service after any revisions become
                        effective, you agree to be bound by the revised terms. If you do not agree
                        to the new terms, you are no longer authorised to use Service.
                    </p>

                    <p>20. Waiver And Severability</p>

                    <p>
                        No waiver by Company of any term or condition set forth in Terms shall be
                        deemed a further or continuing waiver of such term or condition or a waiver
                        of any other term or condition, and any failure of Company to assert a right
                        or provision under Terms shall not constitute a waiver of such right or
                        provision. If any provision of Terms is held by a court or other tribunal of
                        competent jurisdiction to be invalid, illegal or unenforceable for any
                        reason, such provision shall be eliminated or limited to the minimum extent
                        such that the remaining provisions of Terms will continue in full force and
                        effect.
                    </p>

                    <p>21. Acknowledgement</p>

                    <p>
                        BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE THAT YOU
                        HAVE READ THESE TERMS OF SERVICE AND AGREE TO BE BOUND BY THEM.
                    </p>

                    <p>22. Contact Us</p>

                    <div className="points-content">
                        <p>
                            Please send your feedback, comments, requests for technical support by
                            email: emaanreviver@gmail.com.
                        </p>

                        <p>
                            These Terms of Service were created for www.emaanreviver.com by{" "}
                            <a href="https://policymaker.io/" target="_blank" rel="noreferrer">
                                PolicyMaker.io
                            </a>{" "}
                            on 2023-12-31.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TermsConditions;
