import React, { useEffect, Fragment, useState } from "react";
import "./App.scss";
import { AppRouter } from "./AppRouter";
import withClearCache from "./ClearCache";
import Spinner from "./components/Spinner";

const MainApp = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(false);
    }, []);

    return <Fragment>{isLoading ? <Spinner className="main-loader" /> : <AppRouter />}</Fragment>;
};

const ClearCacheComponent = withClearCache(MainApp);

const App = () => {
    return <ClearCacheComponent />;
};

export default App;
