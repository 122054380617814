import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.scss";
import App from "./App";
// import configStore from "./store/configureStore";
import * as serviceWorker from "./serviceWorker";
// const store = configStore();

const container = document.getElementById("app");
const root = createRoot(container);
root.render(
    <StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </StrictMode>,
);

serviceWorker.register();
