import React, { memo } from "react";
import PropTypes from "prop-types";

const Button = memo((props) => {
    const { text, children } = props;
    return (
        <button {...props}>
            {children}
            {text}
        </button>
    );
});

Button.propTypes = {
    text: PropTypes.string,
    customClass: PropTypes.string,
    disabled: PropTypes.bool,
    onClickHandler: PropTypes.func,
    children: PropTypes.node,
};

Button.displayName = "Button";

export default Button;
