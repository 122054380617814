{
    "name": "emaan-reviver-ui",
    "version": "1.2.0",
    "description": "",
    "source": "/src/index.html",
    "scripts": {
        "dev": "parcel --open -p 4000",
        "build:dev": "REACT_APP_ENV=dev parcel build",
        "build:stage": "REACT_APP_ENV=stage parcel build",
        "build:prod": "REACT_APP_ENV=prod parcel build",
        "build:devsite": "REACT_APP_ENV=devsite parcel build",
        "build:prodsite": "REACT_APP_ENV=prodiste parcel build",
        "format": "eslint . --fix",
        "lint": "eslint .",
        "lint:updated": "pipe-git-updated --ext=js -- eslint",
        "prepare": "husky install",
        "prepare-release": "standard-version && prettier --write CHANGELOG.md",
        "prettier": "prettier --check .",
        "prettier-check": "prettier -c --ignore-path .gitignore \"**/*.{css,scss,html,js,json,md,yaml,yml,gql,graphql}\"",
        "prettier-check:updated": "pipe-git-updated --ext=css --ext=scss --ext=html --ext=js --ext=json --ext=md --ext=yaml --ext=yml --ext=gql --ext=graphql -- prettier -c",
        "prettify": "prettier --write --ignore-path .gitignore .babelrc \"**/*.{css,scss,html,js,json,md,yaml,yml,gql,graphql}\"",
        "prettify:updated": "pipe-git-updated --ext=css --ext=scss --ext=html --ext=js --ext=json --ext=md --ext=yaml --ext=yml --ext=gql --ext=graphql -- prettier --write"
    },
    "repository": {
        "type": "git",
        "url": ""
    },
    "keywords": [],
    "author": "",
    "license": "ISC",
    "standard-version": {
        "skip": {
            "commit": true,
            "tag": true
        },
        "types": [
            {
                "type": "feat",
                "section": "Features"
            },
            {
                "type": "fix",
                "section": "Bug Fixes"
            },
            {
                "type": "perf",
                "section": "Performance Improvements"
            },
            {
                "type": "refactor",
                "section": "Maintenance Improvements"
            }
        ]
    },
    "dependencies": {
        "@apollo/client": "^3.6.9",
        "axios": "^1.0.0",
        "bootstrap": "^5.2.2",
        "dotenv": "^16.0.0",
        "enzyme": "^3.11.0",
        "enzyme-adapter-react-16": "^1.15.5",
        "moment": "^2.29.0",
        "prop-types": "^15.8.1",
        "react": "^18.2.0",
        "react-dom": "^18.2.0",
        "react-redux": "^9.1.0",
        "react-router-dom": "^6.4.2",
        "redux": "^5.0.1",
        "typesafe-actions": "^5.1.0"
    },
    "engines": {
        "node": "18.x"
    },
    "devDependencies": {
        "@babel/core": "^7.11.6",
        "@babel/eslint-parser": "^7.19.1",
        "@babel/plugin-proposal-class-properties": "^7.18.6",
        "@babel/plugin-proposal-throw-expressions": "^7.22.5",
        "@babel/plugin-syntax-dynamic-import": "^7.8.3",
        "@babel/plugin-syntax-jsx": "^7.22.5",
        "@babel/plugin-transform-react-jsx": "^7.12.13",
        "@babel/plugin-transform-runtime": "^7.22.5",
        "@babel/polyfill": "^7.12.1",
        "@commitlint/cli": "^19.2.1",
        "@commitlint/config-conventional": "^19.1.0",
        "@parcel/reporter-bundle-analyzer": "^2.9.2",
        "@parcel/transformer-sass": "^2.9.3",
        "babel-core": "^6.26.3",
        "babel-jest": "^29.1.2",
        "buffer": "^5.5.0||^6.0.0",
        "eslint": "^8.24.0",
        "eslint-config-airbnb-base": "^15.0.0",
        "eslint-config-jest-enzyme": "^7.1.2",
        "eslint-config-prettier": "^9.1.0",
        "eslint-plugin-babel": "^5.3.1",
        "eslint-plugin-import": "^2.26.0",
        "eslint-plugin-jest": "^27.1.1",
        "eslint-plugin-jsx-a11y": "^6.6.1",
        "eslint-plugin-prettier": "^5.1.3",
        "eslint-plugin-react": "^7.31.8",
        "eslint-plugin-react-hooks": "^4.6.0",
        "git-list-updated": "^1.2.1",
        "husky": "^9.0.11",
        "jest": "^29.1.2",
        "lint-staged": "^15.2.2",
        "os-browserify": "^0.3.0",
        "parcel": "^2.9.3",
        "parcel-reporter-static-files-copy": "^1.5.0",
        "path": "^0.12.7",
        "path-browserify": "^1.0.0",
        "prettier": "^3.2.5",
        "react-test-renderer": "^18.2.0"
    },
    "browserslist": {
        "production": [
            ">0.2%",
            "not dead",
            "not op_mini all"
        ],
        "development": [
            "since 2017-06"
        ]
    },
    "buildDate": 1667661146107
}
